<template>
  <svg
    class="panzoom"
    :viewBox="`${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`"
  >
    <g ref="drag">
      <slot></slot>
    </g>
  </svg>
</template>
<script>
import panzoom from "panzoom";
import { ref, onMounted } from "vue";

export default {
  props: {
    viewBox: Object,
  },
  setup() {
    var drag = ref(null);
    onMounted(() => {
      panzoom(drag.value, {
        maxZoom: 5,
        minZoom: 0.5,
        zoomSpeed: 0.2,
      });
    });
    return { drag };
  },
};
</script>
<style lang="scss">
.panzoom {
  overflow: hidden;
  outline: none;
  cursor: move;
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  width: 100%;
  height: 100%;
}
</style>