<template>
  <article id="buildingsmap">
    <section class="layer-1">
      <panzoommap :viewBox="{ x: 0, y: 0, w: 1920, h: 1080 }">
        <g id="hintergrund">
        </g>
        <g id="buildings">
          <buildingonmap
            v-for="(building, index) in buildings"
            :key="index"
            :svg="building.small_icon"
            :x="building.positionx"
            :y="building.positiony"
            :id="building.id"
          ></buildingonmap>
        </g>
      </panzoommap>
    </section>
    <section class="layer-2">
      <sidecard
        :title="activebuilding.name"
        :description="activebuilding.description"
        :closebuttontext="t('buildings_map.renovate_button')"
        :showcard="showsidecard"
        @close="closesidecard"
        @go="openbuilding"
      ></sidecard>
    </section>
  </article>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import panzoommap from "../components/panzoommap";
import buildingonmap from "../components/building-on-map";
import sidecard from "../components/side-card";

export default {
  name: "builingsmap",
  components: { buildingonmap, sidecard, panzoommap },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    function closesidecard() {
      store.commit("set_cardbuildings", false);
    }
    function openbuilding() {
      router.push({
        name: "building",
        params: {
          build_id: store.state.buildings.active_building_id,
        },
      });
      store.commit("set_cardbuildings", false);
    }
    return {
      buildings: computed(() => store.state.buildings.buildings),
      activebuilding: computed(() => store.state.buildings.active_building),
      showsidecard: computed(() => store.state.buildings.sidecard),
      closesidecard,
      openbuilding,
      t
    };
  },
};
</script>

<style lang="scss">
#buildingsmap {
  width: 100%;
  height: 100%;
}
</style>