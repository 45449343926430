<template>
  <g
    @click="open_card()"
    @touchend="open_card()"
    class="house-on-map"
    :transform="`translate(${x},${y})`"
    v-html="svg"
  ></g>
</template>
<script>
import { useStore } from "vuex";
export default {
  name: "buildingonmap",
  props: ["svg", "x", "y", "id"],
  setup(props) {
    const store = useStore();
    function open_card() {
      store.commit("set_current_building", props.id);
      store.commit("set_cardbuildings", true);
    }
    return {
      open_card,
    };
  },
};
</script>
<style lang="scss">
.house-on-map {
  cursor: pointer;
}
.house-on-map:hover {
}
</style>